import mainjson from '../config/main';
export default function GetData() {
    return {
        "apitype": "suggestions",
        "id": "suggestion_id",
        "subidname": "suggestion_title",
        "options": ["edit", "delete"],
        "nameSingle": "suggestion",
        "nameMultiple": "suggestions",
        "extraoptions":[
            {
                "name":"Map",
                "page":"/pointsmap/?type=suggestion&suggestionid=[suggestion_id]",
                "type":"custom"
            }
        ],
        "fields": {
            "field0": {
                "name": "ID",
                "field": "suggestion_id",
                "type": "Display",
                "list": true,
            },
            "field1": {
                "name": "Title",
                "field": "suggestion_title",
                "type": "Display",
                "list": true,
            },
            "field2": {
                "name": "Description",
                "field": "suggestion_desc",
                "type": "Display",
                "list": false,
            },
            "field3": {
                "name": "E-mail",
                "field": "suggestion_email",
                "type": "Display",
                "list": false,
            },
            "field4": {
                "name": "Latitude",
                "field": "suggestion_lat",
                "type": "Display",
                "list": false,
            },
            "field5": {
                "name": "Longitude",
                "field": "suggestion_lng",
                "type": "Display",
                "list": false,
            },
            "field6": {
                "name": "Date",
                "field": "suggestion_date",
                "type": "DateTimeInput",
                "list": true,
            },
        }
    }
}